@import '@/styles/variables.scss';

.Main {
  container-type: inline-size;
}

.FixedHeader {
  top: 0;
  position: sticky;
  z-index: 10;
  box-shadow: $box-shadow-1;
}
