@import '@/styles/variables.scss';

.TextLink {
  color: $sbl-blue-1;
  @include hover {
    color: $sbl-blue-5;
  }
  display: inline-flex;
  gap: 8px;
  align-items: center;
}

a.TextLink:not([href]) {
  color: inherit;
  cursor: inherit;
}

.Small {
  @include font(14px, SEMIBOLD);
}

.Medium {
  @include font(15px, 'MEDIUM');
}

.Large {
  @include font(17px, 'MEDIUM');
}
