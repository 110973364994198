@import '~styles/global';

.FiltersContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;

  .FilterSort {
    margin-left: auto;
  }

  @include small {
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
    overflow: scroll;

    select {
      flex-shrink: 0;
    }
  }
}
