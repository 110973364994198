@import '~styles/global';

.CollectionTitle {
  font-size: 25px;
  line-height: 30px;
  font-weight: 900;
  margin-bottom: 25px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .CollectionAll {
    color: $sbl-blue-1;
    font-size: 17px;
    line-height: 20px;
    font-weight: 400;

    display: flex;
    align-items: center;
    gap: 16px;

    @include small {
      display: none;
    }
  }

  @include small {
    margin-left: 10px;
    margin-right: 10px;
  }
}
