@import '@/styles/variables.scss';

.Container {
  position: fixed;
  bottom: 0;
  right: 0;
  color: #fff;
  @include font(14px);
  padding: 4px 10px;
  background-color: rgba(255, 0, 0, 0.7);
}

.Staging {
  background-color: rgba(0, 0, 255, 0.7);
}

.Development {
  background-color: rgba(0, 125, 0, 0.7);
}
