@import '~styles/global';

$img-path: '../../../../../assets/images';

.SearchBackground {
  background:
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('#{$img-path}/photo-galleries-header.jpg') no-repeat;
  background-size: cover;
  background-position: 0px -100px;
  height: 439px;
  position: relative;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include small {
    background-position: -90px 0px;
  }

  .SearchTagline {
    color: #fff;
    font-weight: 900;
    font-size: 55px;

    @include small {
      font-size: 34px;
      text-align: center;
    }
  }

  .SearchContainer {
    display: flex;
    justify-content: center;
    width: 774px;

    @include small {
      width: 344px;
      flex-wrap: wrap-reverse;
    }

    .SearchStates {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .SearchSports {
      border-radius: 0px;
      border-right: none;
      border-left: none;

      @include small {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .SearchStates,
    .SearchSports {
      font-size: 13px;
      font-weight: 500;
      width: 114px;

      &:hover {
        background-color: $sbl-gray-blue-10;
      }

      @include small {
        height: 42px;
        width: 50%;
      }
    }

    .SearchInput {
      width: auto !important;
      border-radius: 0px;
      flex-basis: 404px;
      flex-grow: 0;

      border: 1px solid $sbl-blue-gray-1;
      height: 50px;
      font-size: 15px;

      @include medium {
        height: 42px;
      }

      @include small {
        height: 42px;
        flex-basis: 302px;
        flex-grow: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      padding: 0 15px;
      width: 100%;

      &::placeholder {
        color: $sbl-gray-blue-3;
      }

      &:hover {
        border-color: #a8b0c1;
      }

      &:focus {
        border-color: $sbl-blue-7;
      }
    }

    .SearchButton {
      background: $sbl-blue-1;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: $sbl-blue-5;
        cursor: pointer;
      }
    }

    .SearchInput,
    .SearchButton {
      @include small {
        margin-bottom: 8px;
      }
    }
  }

  .PhotographyNetworkIcon {
    position: absolute;
    right: 30px;
    bottom: 20px;

    @include small {
      left: 50%;
      margin-left: -73px;
    }
  }
}

.SearchInfo {
  margin-bottom: 24px;

  @include small {
    margin-left: 10px;
    margin-right: 10px;
  }

  .SearchTerm {
    font-size: 19px;
    line-height: 23px;
    font-weight: 700;
    padding-bottom: 2px;
  }

  .SearchResultCount {
    color: $sbl-gray-blue-4;
    font-size: 11px;
    line-height: 13px;
  }
}
