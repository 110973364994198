@import '@/styles/variables.scss';

.PageSection {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;

  padding-left: 14px;
  padding-right: 14px;

  @include large {
    padding-left: 32px;
    padding-right: 32px;
  }
}

.FullWidth {
  max-width: none;
  margin-left: 0;
  margin-right: 0;
}

.FlushSmall {
  @include small {
    padding-left: 0;
    padding-right: 0;
  }
}

.FlushMedium {
  @include not-large {
    padding-left: 0;
    padding-right: 0;
  }
}
