@import '@/styles/variables.scss';

.Ad {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TOP {
  margin: 0 auto;
  min-height: 270px;

  @media (max-width: 719px) {
    min-height: 60px;
  }

  &.Placeholder {
    max-width: 970px;
  }
}

.BOTTOM {
  position: sticky;
  bottom: 0px;

  &.Placeholder {
    min-height: 50px;
  }
}

.SIDEBAR,
.SIDEBAR_2 {
  &.Placeholder {
    min-height: 250px;
  }
}

.IN_FEED,
.IN_FEED_2 {
  min-height: 270px;
  margin: 20px auto;

  &.Placeholder {
    width: 300px;
  }
}
