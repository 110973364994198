@import '@/styles/variables.scss';

.Ad {
  @media print {
    display: none !important;
  }
}

.Placeholder {
  background-color: #002241;
  color: #00519c;
  @include font(14px, SEMIBOLD);

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dotted #00519c;
}
