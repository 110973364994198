@import '@/styles/variables.scss';

.PhotoGalleryGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 25px;

  a {
    min-width: 412px;
  }

  @include small {
    grid-template-columns: repeat(1, 1fr);

    a {
      margin: 0 10px;
      min-width: unset;
    }
  }
}

.NoGalleries {
  margin: 10px 12px;

  a {
    text-decoration: underline;
  }
}

.AdFullWidth {
  grid-column: 1 / -1;
}
