@import '~styles/global';

$img-path: '../../../assets/images';

.Footer {
  display: flex;
  box-sizing: border-box;
  background-color: #4d4d4d;
  flex-direction: column;
  align-items: center;
  padding-top: 35px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;

  margin-top: 10px;
  padding-bottom: 60px;

  @include medium {
    margin-top: 50px;
    padding-bottom: 24px;
  }

  @include large {
    margin-top: 100px;
  }

  a {
    color: white;
    text-decoration: none;

    &:hover {
      color: $sbl-gray-10;
    }
  }

  @media print {
    display: none;
  }
}

.Sticky {
  position: sticky;
  bottom: 0;
  animation: slide 0.5s cubic-bezier(0.5, 0, 1, 1);
}

@keyframes slide {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.FooterNav {
  display: flex;
  flex-direction: column;
  font-weight: $font-weight-heavy;
  align-items: center;
  margin-bottom: 20px;

  @include medium {
    margin-bottom: 50px;
  }

  ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.FooterNavMain {
  font-size: 12px;
  border-bottom: 2px solid grey;
  padding: 0 0 5px 0;

  @include medium {
    font-size: 20px;
    border-bottom-width: 3px;
  }

  li:first-of-type {
    margin-right: 30px;

    @include medium {
      margin-right: 60px;
    }
  }
}

.FooterSubnav {
  font-weight: $font-weight-bold;
  font-size: 10px;

  a {
    padding: 6px 0;
    display: block;
  }

  @include medium {
    font-size: 14px;
  }

  li:not(:last-of-type) {
    margin-right: 30px;

    @include medium {
      margin-right: 60px;
    }
  }
}

.FooterCopyright {
  color: white;
  font-size: 7px;
  font-weight: $font-weight-regular;

  @include medium {
    font-size: 11px;
  }
}

.FooterIcons {
  margin-bottom: 10px;

  a {
    height: 24px;
    width: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 15px;

    @include medium {
      height: 42px;
      width: 42px;
      margin-right: 30px;
    }

    &:hover {
      opacity: 0.7;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.Footer :global {
  .twitter--grey {
    background-image: url('#{$img-path}/footer/twitter.png');
  }

  .facebook--grey {
    background-image: url('#{$img-path}/footer/facebook.png');
  }

  .instagram--grey {
    background-image: url('#{$img-path}/footer/instagram.png');
  }

  .linkedin--grey {
    background-image: url('#{$img-path}/footer/linkedin.png');
  }

  .youtube--grey {
    background-image: url('#{$img-path}/footer/youtube.png');
  }
}
