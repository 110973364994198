@import './variables.scss';

html {
  font-size: 16px;
}

body.page {
  margin: 0;
  background: #eff0f3;

  &:not(.gray-bg) {
    @include medium {
      background: #ffffff;
    }
  }
}

body,
select,
button,
option,
input[type='date' i],
input[type='time' i] {
  font-family: $font-family;
}

* {
  box-sizing: border-box;
}

// TODO fix to work w/ Clickable component
button {
  all: unset;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  &:focus-visible {
    border-color: $sbl-blue-7;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

a {
  all: unset;
  box-sizing: border-box;
  display: inline;
  cursor: pointer;
}
