@import '@/styles/variables.scss';

.SelectInput {
  cursor: pointer;
  border-radius: 8px;
  padding-right: 30px;
  appearance: none;
  background-size: 11px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-image: url('../../../../../assets/images/icons/chevron-down.svg');
  //width: 100%;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.White {
  height: 50px;
  @include medium {
    height: 42px;
  }

  padding-left: 15px;
  background-color: #fff;
  border: 1px solid $sbl-blue-gray-1;
  @include font(15px);

  &:hover {
    border-color: #a8b0c1;
  }

  &:focus {
    border-color: $sbl-blue-7;
  }
}

.Gray {
  border: none;
  height: 34px;
  padding-left: 11px;
  background-color: $sbl-gray-1;
  border-radius: 17px;
  @include font(13px, SEMIBOLD);

  &:hover {
    background-color: $sbl-gray-4;
  }
}
