@import '@/styles/variables.scss';

.Section {
  background-color: #fff;
  padding: 24px 14px;

  @include medium {
    border-top: 1px solid $sbl-gray-blue-1;
    border-bottom: 1px solid $sbl-gray-blue-1;
    padding: 0;
    border: 0;
  }
}

.FlushSmall,
.FlushMedium {
  margin-left: -14px;
  margin-right: -14px;
}

.FlushSmall {
  @include medium {
    margin-left: 0;
    margin-right: 0;
  }
}

.FlushMedium {
  @include large {
    margin-left: 0;
    margin-right: 0;
  }
}

.FooterButton {
  display: flex !important;
  margin-top: 20px;

  @include large {
    margin-left: 14px;
    margin-right: 14px;
  }
}
