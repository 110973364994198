@import '~styles/global';

$img-path: '../../../../../assets/images';

.SearchText {
  position: relative;
  width: 400px;
  max-width: 100%;
  margin-bottom: 20px;

  @include small {
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
  }

  input {
    height: 42px;
    width: 100%;
    background-color: white;
    border: 1px solid $sbl-blue-gray-1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', sans-serif;
    font-weight: $font-weight-regular;
    font-size: 15px;
    padding: 5px 0 5px 15px;
    text-transform: none;
    min-width: 100px;
    border-radius: 10px;
    white-space: nowrap;
    appearance: none;

    &::placeholder {
      color: $sbl-gray-blue-3;
    }

    @include medium {
      height: 48px;
      padding: 5px 0 5px 15px;
    }

    &:hover {
      outline: none;
      border: 1px solid #a8b0c1;
    }

    &:focus {
      border: 1px solid $sbl-blue-7;
      box-shadow: 0 0 0 4px $sbl-light-blue-3;
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.ClearText {
  position: absolute;
  right: 15px;
  top: 13px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 4;
}

.Selectors {
  margin-bottom: 30px;
}
