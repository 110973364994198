@import '@/styles/variables.scss';

.PageLayout {
  margin-top: 20px;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  gap: 5px;

  @include medium {
    margin-top: 40px;

    &.Content {
      gap: 30px;
    }

    &.Panels {
      gap: 20px;
    }
  }

  @include large {
    margin-top: 60px;

    &:not(.FullWidth) {
      display: grid;
      grid-template-columns: minmax(0, 1fr) min(390px, 33%);
    }
  }
}

.Header {
  @include medium {
    .Content & {
      padding-bottom: 30px;
      border-bottom: 5px solid $sbl-gray-blue-9;
    }

    .Panels & {
      border: 2px solid $sbl-gray-blue-1;
      border-radius: 20px;
      padding: 25px 20px;
    }
  }

  @include large {
    grid-row: 1;
    grid-column: 1 / -1;

    .Content & {
      border-width: 1px;
    }
  }
}

.Main,
.Sidebar {
  display: contents;

  @include medium {
    .Content & > :not(:empty) {
      padding-bottom: 30px;
      border-bottom: 5px solid $sbl-gray-blue-9;
    }

    .Panels & > :not(:empty) {
      border: 2px solid $sbl-gray-blue-1;
      border-radius: 20px;
      padding: 25px 20px;
    }
  }

  @include large {
    display: flex;
    flex-direction: column;
    container-type: inline-size;

    .Content & {
      gap: 30px;

      & > :last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    .Panels & {
      gap: 20px;
    }
  }
}

.Main {
  @include large {
    .Content:not(.FullWidth) & {
      padding-right: 65px;
      border-right: 1px solid $sbl-gray-blue-1;
    }
  }
}

.Module {
  @include medium {
    order: unset !important;
  }
  &:empty {
    display: none;
  }
}
