@import '~styles/global';

.TileContainer {
  position: relative;
  display: flex;
  align-items: end;
  height: 180px;
  width: auto;
  border-radius: 10px;
  overflow: hidden;
  @include medium {
    height: 247px;
  }

  .CoverImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1;
    @include medium {
      height: 150px;
    }
  }

  .PhotoCount {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 2;

    .PhotoCountDisplay {
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      border-radius: 8px;
      font-size: 13px;
      line-height: 20px;
      padding: 9px;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &:hover .PhotoCount {
    opacity: 1;
  }
}

.TileDetails {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 12px;
  padding-bottom: 12px;
}

.GalleryDate {
  text-transform: uppercase;
  font-size: 9px;
  color: #fff;
  font-weight: $font-weight-bold;
}

.TeamText {
  color: #fff;
  font-size: 13px;
  font-weight: $font-weight-black;
}

.PhotographerCredit {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-weight: $font-weight-semibold;
  font-size: 11px;
}

.Dot {
  background: #fff;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
