@import '@/styles/variables.scss';

.AdContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Ad {
  display: block;
  margin: 25px auto;
  max-width: 90%;

  @include medium {
    max-height: 90px;
    max-width: 720px;
  }
}
