@import '@/styles/variables.scss';

.Container {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.TOP {
  @include medium {
    margin-top: 30px;
  }
}

.Spacer {
  padding-bottom: 56.25%;
  position: relative;
  @media print {
    display: none;
  }
}

.Ad {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// when the STN script is in the page, but not a corresponding div#stn-player
// the STN script adds a div as the first child of the body and the embedded video is
// full width and height, the  rule below hides that it

// if we get Minute Media to change their script, we can remove this
:global body > .shadow-root-voltax-mp {
  display: none;
}
